<template>
    <div>
        <Property :property.sync="property" />
        <div class="uk-padding-small uk-align-right">
            <vk-button :disabled="recordPending" style="border: solid black 1px" type="primary" @click="recordProperty">
                Enregistrer
                <div v-if="recordPending" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
            </vk-button>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'
import Property from '@/components/Property'
import { mapActions } from 'vuex'

export default {
    name: 'PropertyEdit',
    components: { Property },
    data: () => ({
        recordPending: false,
        defaultProps: {
            property: Property.props.property.default,
        },
        property: Property.props.property.default(),
    }),
    props: {},
    methods: {
        recordProperty() {
            this.recordPending = true
            backendApi
                .createProperty(store.state.login.user.token, this.property)
                .then((data) => {
                    this.$router.push({ name: 'property_edit', params: { propertyId: data.id } })
                    this.addNotification({ message: 'Propriété enregistrée avec succès', status: 'success' })
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    this.recordPending = false
                })
        },
        ...mapActions('notifications', ['addNotification']),
    },
    mounted() {},
    updated() {},
    beforeUpdate() {},
    beforeCreate() {},
}
</script>

<style scoped></style>
